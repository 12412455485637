import React from "react"
import PropTypes from "prop-types"

import BlogBlock from "../blogs/BlogBlock"

const BlogList = ({ title, subtitle, recentPosts }) => (
  <section id="bloglist">
    <div className="absolute top-0 h-72 bg-sym-gray w-full"></div>
    <div className="z-10 relative pt-28">
      <header className="blog-title">
        <h1 className="text-center font-bold text-5xl text-white">
          Publicaciones
        </h1>
      </header>

      <div className="container mx-auto flex flex-wrap mt-10 px-6 justify-center">
        {recentPosts.length > 0 &&
          recentPosts.map(b => <BlogBlock key={b.slug} blog={b} />)}
      </div>
    </div>
  </section>
)

BlogList.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  recentPosts: PropTypes.array,
}

BlogList.defaultProps = {
  title: `Últimas Entradas`,
  subtitle: ``,
  recentPosts: [],
}

export default BlogList
