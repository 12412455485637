import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ToTop from "../components/common/ToTop"
import Contact from "../components/home/Contact"
import BlogList from "../components/blogs/BlogList"

const BlogPage = ({ location }) => {
  const { allStrapiBlog } = useStaticQuery(query)

  const { nodes: posts } = allStrapiBlog

  return (
    <Layout pathname={location.pathname || "/blog"}>
      <Seo
        title="Blog SymLab - Aplicaciones web y móviles"
        description="Artículos de tecnología, y de aplicaciones web y móviles"
        url="/blog"
      />
      <BlogList title={"Publicaciones"} recentPosts={posts} />
      <Contact />
      <ToTop />
    </Layout>
  )
}

const query = graphql`
  query {
    allStrapiBlog(
      filter: { status: { eq: "published" } }
      sort: { fields: PublishDate, order: DESC }
    ) {
      nodes {
        id
        PublishDate
        title
        slug
        status
        excerpt
        image: cover_image {
          caption
          alternativeText
          formats {
            posts {
              width
              height
              url
            }
          }
        }
      }
    }
  }
`

export default BlogPage
