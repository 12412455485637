import React from "react"
import PropTypes from "prop-types"

import "./blog-block.css"

const BlogBlock = ({ blog }) => {
  const date = new Date(blog.PublishDate)
  return (
    <div className="blog-block w-full md:w-1/3 p-4 relative bg-transparent rounded-md">
      <a href={`/blog/${date.getFullYear()}/${blog.slug}/`}>
        <div className="blog-content  rounded-md h-full transition-transform duration-500 ease-in-out transform hover:scale-105">
          <div className="blog-overlay"></div>
          <div className="blog-image">
            <img
              src={blog.image.formats.posts.url}
              alt={blog.image.alternativeText}
            />
          </div>
          <div className="absolute blog-title">
            <h3 className="text-xl text-white font-semibold mb-4">
              {blog.title}
            </h3>
          </div>
          <div className="absolute blog-description">
            <p className="text-white text-xl leading-8">Leer más...</p>
          </div>
        </div>
      </a>
    </div>
  )
}

BlogBlock.propTypes = {
  blog: PropTypes.object,
}

export default BlogBlock
